.main
  padding-top: 2rem
  p
    overflow-wrap: break-word
  .introduction
    &__title
      margin: 0
    &__image
      display: none
  &__title
    margin-bottom: 4rem
    color: $color-green
  .github
    display: flex
    align-items: center
    &__icon
      width: 2rem
      margin-right: 1rem
  .mark
    height: 2rem
  .container
    margin-botom: 3rem
  h2
    font-weight: normal
    margin: 3rem 0
    line-height: 2rem
  .success__stories
    margin-bottom: 6rem
    .main__title
      margin-bottom: 0
    p
      text-align: center
    img
      margin-top: 3rem
      max-width: 10rem
  table td
    &:first-child, &:last-child
      word-break: break-all
